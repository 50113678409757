import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { ProductRequestList } from '@/resource/model';
import { decimalToNumber2 } from '@/utils';
import Decimal from 'decimal.js';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class DetailedSummary extends mixins(PagingMixin) {
  @Prop({
    type: Array,
    required: true,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<Partial<ProductRequestList>>;

  public tableOptions: OsTableOption<Partial<ProductRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: () => {
      return 'uuid';
    },
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count',
        fixPlace: 2
      },
      {
        prop: 'settlementArea',
        fixPlace: 2
      },
      {
        prop: 'discountAmount',
        fixPlace: 2
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      }
    ]
  };

  public columnOptions: Array<OsTableColumn<ProductRequestList>> = [
    {
      type: 'index',
      prop: 'itemId',
      label: 'common.index',
      width: '80px',
      fixed: 'left'
    },
    {
      prop: 'itemName',
      label: 'product.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'priceUnitName',
      label: 'customerProduct.chargeUnit',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'billing.totalNumber',
      minWidth: '160px',
      formatter(rowData: Object): String {
        return (rowData as ProductRequestList).count.toFixed(2);
      }
    },
    {
      prop: 'settlementArea',
      label: 'billing.priceCount',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter(rowData: Object): String {
        return (rowData as ProductRequestList).settlementArea.toFixed(6);
      }
    },
    {
      prop: 'discountAmount',
      label: 'billing.discountAmount',
      minWidth: '160px',
      formatter(rowData: Object): String {
        return (rowData as ProductRequestList).discountAmount.toFixed(2);
      }
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      formatter(rowData: Object): String {
        return (rowData as ProductRequestList).amountExcludingTax.toFixed(2);
      }
    }
  ];

  /**
   * 分类统计
   * @params data Array
   * @params key1、key2 分类条件
   */
  public getGroup(data: Array<ProductRequestList>): Array<any> {
    const groups: any = [];
    data.forEach(c => {
      const value: any = c['itemName'] + c['priceUnit'];
      groups[value] = groups[value] || [];
      groups[value].push(c);
    });

    const result: any = [];
    Object.values(groups).forEach((cur: any) => {
      let count: number = 0; // 总数
      let settlementArea: number = 0; // 总面积
      let discountAmount: number = 0; // 优惠金额
      let amountExcludingTax: number = 0; // 未税金额
      cur.forEach((item: ProductRequestList) => {
        count = decimalToNumber2(new Decimal(count).add(new Decimal(item.count)));
        settlementArea = decimalToNumber2(new Decimal(settlementArea).add(new Decimal(item.settlementArea)));
        discountAmount = decimalToNumber2(new Decimal(discountAmount).add(new Decimal(item.discountAmount)));
        amountExcludingTax = decimalToNumber2(
          new Decimal(amountExcludingTax).add(new Decimal(item.amountExcludingTax))
        );
      });
      result.push({
        itemName: cur[0].itemName,
        priceUnitName: cur[0].priceUnitName,
        count: count,
        settlementArea: settlementArea,
        discountAmount: discountAmount,
        amountExcludingTax: amountExcludingTax
      });
    });
    return result;
  }

  @Watch('tableData', { deep: true, immediate: true })
  private handleDataList(value: Array<ProductRequestList>): void {
    this.tableOptions.data = this.getGroup(value);
  }
}
